html.alpina {
  --primary: #333132;
  --primary-hover: #1b1a1b;
  --primary-color: #ffffff;

  --error: #dd4600;
  --error-hover: #af3801;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #eff2f3;
  --border: #d1d5d9;

  --text: #d6244e;
  --text-light: #abb1b6;
  --text-lightest: #c6ced4;

  --dark-main: #d6244e;

  --dark-text: #ffffff;
  --dark-text-light: #c9d3df;
}
