html.lykke {
  --primary: #cfa642;
  --primary-hover: #aa8a38;
  --primary-color: #ffffff;

  --error: #e91e63;
  --error-hover: #880e4f;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #eff2f3;
  --border: #d1d5d9;

  --text: #465436;
  --text-light: #abb1b6;
  --text-lightest: #c6ced4;

  --dark-main: #465436;

  --dark-text: #ffffff;
  --dark-text-light: #c9d3df;
}
