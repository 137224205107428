html.dsv,
html.dsvcadres {
  --primary: #e51a29;
  --primary-hover: #555047;
  --primary-color: #ffffff;

  --error: #dd4600;
  --error-hover: #af3801;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #f7f7f7;
  --border: #d1c6b1;

  --text: #3a352f;
  --text-light: #463f39;
  --text-lightest: #555047;

  --dark-main: #463f39;

  --dark-text: #ffffff;
  --dark-text-light: #f7f7f7;
}
