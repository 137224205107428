@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes noResult {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*@keyframes noResultIcon {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}*/

@keyframes noResultIcon {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.popup-animation {
  animation: noResultIcon 0.5s forwards;
  animation-delay: 0.5s;
  opacity: 0;
  transform: scale(0);
}

.slide-in-from-right {
  transform: translateX(100px);
  opacity: 0;
  animation: slideIn 0.5s forwards;
}
