html.restogolf {
  --primary: #e62020;
  --primary-hover: #bd1b1b;
  --primary-color: #ffffff;

  --error: #dd4600;
  --error-hover: #af3801;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #e7dfdf;
  --border: #7692bb;

  --text: #000000;
  --text-light: #054597;
  --text-lightest: #7692bb;

  --dark-main: #014398;

  --dark-text: #ffffff;
  --dark-text-light: #cdd5df;
}
