@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/scss/colors.scss";
@import "ngx-toastr/toastr";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

*:not(mat-icon) {
  font-family: "Figtree", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Inter var, Helvetica Neue, Arial, Noto Sans,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol,
    "Noto Color Emoji" !important;
  margin: 0;
  box-sizing: border-box;
}

html {
  --mat-sys-primary: var(--primary);
  --mat-sys-on-primary: var(--primary-color);
  --mat-sys-surface: var(--main);
  --mat-sys-on-surface: var(--text);
  --mat-sys-surface-variant: var(--main-light);
  --mat-sys-on-surface-variant: var(--text-light);
  --mat-sys-outline: var(--border);
  --mat-sys-error: var(--error);
  --mat-sys-secondary-container: var(--primary-hover);
  --mat-sys-on-secondary-container: var(--primary-color);
  --mat-sys-primary-container: var(--primary-hover);
  --mat-sys-on-primary-container: var(--primary-color);
  --mat-sys-surface-container: var(--main-light);

  --mat-tab-header-divider-color: var(--border);
}

form {
  label {
    @apply text-sm font-semibold;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-[var(--text)];
}

app-planning-material,
app-incidents,
app-reg-pointage,
app-reg-prime,
app-reg-pointage-paie,
app-compteurs,
app-recap-prime,
app-users,
app-documents,
app-contracts {
  @apply size-full;
}

app-agenda {
  height: 100%;
  position: relative;
  display: block;
  overflow: auto;
}

.color-point {
  width: 20px;
  height: 20px;
  margin-right: 9px;
  margin-left: 9px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 9999px;
}

.important-title {
  @apply font-semibold;
}

.darker-txt {
  @apply text-[var(--text)];
}

.txt-danger {
  @apply text-[var(--error)];
}

.spacer-8 {
  @apply w-full h-2;
}

.spacer-16 {
  @apply w-full h-4;
}

.spacer-32 {
  @apply w-full h-8;
}

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-select-search-inner {
  background-color: var(--main-light) !important;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.filters-sidenav {
  @apply transition-all duration-700;
  &:not(.opened) {
    @apply -translate-x-full opacity-0 absolute;
  }
  &.opened {
    @apply translate-x-0 opacity-100 block relative;
  }
}

.floating-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1001;
}

.checkbox-dark .mdc-checkbox__background {
  border-color: var(--text) !important;
}

.checkbox-light .mdc-checkbox__background {
  border-color: var(--dark-text) !important;
}

.button-toggle-col {
  flex-direction: column !important;
  border-radius: 0.75rem !important;
}

.mat-drawer-container {
  background: var(--main-light) !important;
}

.profil-circle {
  @apply bg-[var(--primary-hover)] text-[var(--primary-color)] overflow-hidden relative;
  &.active {
    @apply bg-[var(--primary)] text-[var(--primary-color)];
  }
  img {
    @apply object-cover absolute top-0 size-full object-top;
  }
}

.bg-second {
  background: var(--main) !important;
}

.mat-mdc-icon-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.cal-month-view .cal-header .cal-cell {
  padding: 5px !important;
}

.scrolled-table {
  overflow: auto;
  max-width: 100%;
}

.no-margin-field-bottom {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.bg-primary {
  @apply bg-[var(--primary)] text-[var(--primary-color)];
}

.border-primary {
  @apply border-[var(--primary)] !important;
}

.bg-error {
  @apply bg-[var(--error)] text-[var(--error-color)];
}

.border-error {
  @apply border-[var(--error)] !important;
}

.activeDay {
  border: solid 3px var(--primary) !important;
}

.fill-dark {
  fill: var(--text);
}

.fill-dark-light {
  fill: var(--text-light);
}

.fill-primary-50 {
  fill: var(--text-lightest);
}

.fill-primary-400 {
  fill: var(--primary);
}

.fill-primary-200 {
  fill: var(--primary-hover);
}

.fill-light {
  fill: var(--main-light);
}

.txt-success {
  color: #22c55e !important;
}

.txt-info {
  color: #3b82f6 !important;
}

.txt-warning {
  color: #f59e0b !important;
}

.txt-danger {
  color: var(--error) !important;
}

.txt-white {
  color: var(--text) !important;
}

.mat-drawer-side {
  border-right: none !important;
}

@import "./assets/scss/keyframs.scss";
@import "./assets/scss/alerts.scss";
// @import "./assets/scss/core.scss";
// @import "./assets/scss/structure.scss";
// @import "./assets/scss/elements.scss";
// @import "./assets/scss/cards.scss";
@import "./assets/scss/responsive.scss";
@import "./assets/scss/spinner.scss";
@import "./assets/scss/buttons.scss";
@import "./assets/scss/timeline.scss";
