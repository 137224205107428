html.sofival,
html.genival {
  --primary: #173f71;
  --primary-hover: #0d2542;
  --primary-color: #ffffff;

  --error: #e91e63;
  --error-hover: #880e4f;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #eaeef3;
  --border: #c9d3df;

  --text: #1d1d1d;
  --text-light: #5c5c5c;
  --text-lightest: #b3b3b3;

  --dark-main: #68769c;

  --dark-text: #ffffff;
  --dark-text-light: #c9d3df;
}
