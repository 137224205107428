@use "@angular/material" as mat;

html {
  @include mat.theme(
    (
      color: (
        theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
        use-system-variables: true,
      ),
      typography: (
        use-system-variables: true,
      ),
      density: -2,
    )
  );
}
