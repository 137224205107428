html {
  --primary: #0c49fb;
  --primary-hover: #727cff;
  --primary-color: #ffffff;

  --error: #e91e63;
  --error-hover: #880e4f;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #ebf2f5;
  --border: #ccdee4;

  --text: #121c42;
  --text-light: #6e758c;
  --text-lightest: #d5e3ec;

  --dark-main: #161640;

  --dark-text: #d4dcff;
  --dark-text-light: #8491c7;
}

@import "./themes/serma.scss";
@import "./themes/dsv.scss";
@import "./themes/dsr.scss";
@import "./themes/cogelan.scss";
@import "./themes/sofival.scss";
@import "./themes/rivage.scss";
@import "./themes/lykke.scss";
@import "./themes/alpina.scss";
@import "./themes/croix-blanche.scss";
@import "./themes/prieure.scss";
@import "./themes/altezza.scss";
@import "./themes/montenvers.scss";
@import "./themes/restogolf.scss";
@import "./themes/funcell.scss";
@import "./themes/steerlight.scss";
@import "./themes/bluecime.scss";
