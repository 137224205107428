html.montenvers {
  --primary: #c4262e;
  --primary-hover: #911c22;
  --primary-color: #ffffff;

  --error: #dd4600;
  --error-hover: #af3801;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #f7f7f7;
  --border: #cccccc;

  --text: #000000;
  --text-light: #abb1b6;
  --text-lightest: #d1d5d9;

  --dark-main: #303030;

  --dark-text: #ffffff;
  --dark-text-light: #b6c4ce;
}
