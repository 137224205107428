.btn {
  @apply cursor-pointer flex items-center justify-center gap-2 py-2.5 px-4 rounded-full border border-[var(--border)] text-sm font-semibold transition-all duration-300;
}

.btn-fab {
  @apply flex items-center justify-center size-10 rounded-full border border-[var(--border)] transition-all duration-300;
}

.btn,
.btn-fab {
  mat-icon {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
  }
}

.mdc-button {
  min-height: 42px;
}

.btn-primary {
  @apply bg-[var(--primary)] text-[var(--primary-color)] border-[var(--primary)] hover:bg-[var(--primary-hover)] hover:border-[var(--primary-hover)];
}

.btn-warn {
  @apply bg-[var(--error)] text-[var(--error-color)] border-[var(--error)] hover:bg-[var(--error-hover)] hover:border-[var(--error-hover)];
}

.buttons-list {
  display: flex;
  flex-wrap: wrap;
  a {
    width: 8em;
    height: 8em;
    margin: 0.5em;
    border: solid 1px var(--border);
    padding: 0.5em;
    border-radius: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    color: inherit;
    mat-icon {
      font-size: 40px;
      height: 40px;
      width: 40px;
    }
  }

  a:hover {
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
}
