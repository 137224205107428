html.cogelan {
  --primary: #a9bb33;
  --primary-hover: #412415;
  --primary-color: #ffffff;

  --error: #e91e63;
  --error-hover: #880e4f;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #f0f1e4;
  --border: #d8c1b5;

  --text: #1d1d1d;
  --text-light: #412415;
  --text-lightest: #066d31;

  --dark-main: #066d31;

  --dark-text: #ffffff;
  --dark-text-light: #d8c1b5;
}
