html.rivage {
  --primary: #9e672c;
  --primary-hover: #be7c35;
  --primary-color: #ffffff;

  --error: #e91e63;
  --error-hover: #880e4f;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #eff2f3;
  --border: #d1d5d9;

  --text: #010a2d;
  --text-light: #abb1b6;
  --text-lightest: #c6ced4;

  --dark-main: #010a2d;

  --dark-text: #ffffff;
  --dark-text-light: #c9d3df;
}
