html.altezza {
  --primary: #e20613;
  --primary-hover: #c20612;
  --primary-color: #ffffff;

  --error: #dd4600;
  --error-hover: #af3801;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #eff2f3;
  --border: #d1d5d9;

  --text: #152b43;
  --text-light: #abb1b6;
  --text-lightest: #d1d5d9;

  --dark-main: #152b43;

  --dark-text: #ffffff;
  --dark-text-light: #b6c4ce;
}
