html.dsr,
html.dsrcadres {
  --primary: #d60a77;
  --primary-hover: #6a6a76;
  --primary-color: #ffffff;

  --error: #dd0000;
  --error-hover: #af0101;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #e9ecef;
  --border: #ccc;

  --text: #212529;
  --text-light: #6a6a76;
  --text-lightest: #96969e;

  --dark-main: #ffffff;

  --dark-text: #212529;
  --dark-text-light: #6a6a76;
}
