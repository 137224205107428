.align-center {
  align-items: center !important;
}

.align-end {
  align-items: flex-end !important;
}

.direction-column {
  flex-direction: column !important;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.flex-10 {
  flex: 10%;
}
.flex-20 {
  flex: 20%;
}
.flex-30 {
  flex: 30%;
}
.flex-40 {
  flex: 40%;
}
.flex-50 {
  flex: 50%;
}
.flex-60 {
  flex: 60%;
}
.flex-70 {
  flex: 70%;
}
.flex-80 {
  flex: 80%;
}
.flex-90 {
  flex: 90%;
}
.flex-100 {
  flex: 100%;
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.max-960 {
  max-width: 960px;
  width: 100%;
}

.max-760 {
  max-width: 760px;
  width: 100%;
}

// Petits écrans
@media (max-width: 801px) {
  .hidden-small {
    display: none !important;
  }
  .main-container {
    min-height: calc(100vh - 74px - 16px);
  }
  .subheader-toolbar,
  .params-form {
    mat-form-field,
    select-activites,
    select-cycles,
    select-date,
    select-emplois,
    select-exercices,
    select-installations,
    select-periodes,
    select-salaries,
    select-services,
    select-types-enploi,
    select-types-chantier,
    select-types-incident {
      width: 100% !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    mat-button-toggle-group {
      flex-direction: column;
      width: 100%;
      border-radius: 1em !important;
    }
  }
  .col-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .col-3 {
    grid-template-columns: repeat(1, 1fr);
  }
  .params-form {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .element-toolbar {
    height: auto;
  }
  .elem-container {
    padding: 0 32px;
  }
}

// Grands écrans
@media (min-width: 800px) {
  .hidden-big {
    display: none !important;
  }
}
