.alert-lg,
.alert-sm {
  @apply rounded-full p-0.5 border flex items-center;
  mat-icon {
    font-size: 14px;
    height: 14px;
    width: 14px;
  }
}

.alert-lg {
  @apply text-sm;
}

.alert-sm {
  @apply text-xs;
}

.alert {
  &-default {
    background-color: #1e293b;
    color: #94a3b8;
    border-color: #94a3b8;
    .txt-default {
      color: #94a3b8 !important;
    }
  }

  &-warning {
    background-color: #fef3c7;
    color: #92400e;
    border-color: #92400e;
    .txt-warning {
      color: #92400e !important;
    }
  }

  &-danger {
    background-color: #fce7f3;
    color: #b22b4d;
    border-color: #b22b4d;
    .txt-danger {
      color: #b22b4d !important;
    }
  }

  &-info {
    background-color: #dbeafe;
    color: #3040bc;
    border-color: #3040bc;
    .txt-info {
      color: #3040bc !important;
    }
  }

  &-info-light {
    background-color: #dbeafe;
    color: #00aeff;
    border-color: #00aeff;
    .txt-info {
      color: #00aeff !important;
    }
  }

  &-success {
    background-color: #dcfce7;
    color: #166534;
    border-color: #166534;
    .txt-success {
      color: #166534 !important;
    }
  }
}

.statut {
  font-size: 11px;
  padding: 1px 4px;
  border-radius: 9999px;
  background: #454545;
  color: white;
  margin: 4px;
}

.statut-primary {
  background: var(--primary);
  color: var(--primary-color);
}

.statut-warning {
  background: #ffdcbe;
  color: #343434;
}

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  .body-content {
    padding-top: 50px;
  }
}

.ui-success svg,
.ui-error svg {
  width: 80px;
  height: 80px;
}

.ui-success {
  &-circle {
    stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
    stroke-dashoffset: 260.75219024795285px;
    transform: rotate(220deg);
    transform-origin: center center;
    stroke-linecap: round;
    animation: ani-success-circle 1s ease-in both;
  }
  &-path {
    stroke-dasharray: 60px 64px;
    stroke-dashoffset: 62px;
    stroke-linecap: round;
    animation: ani-success-path 0.4s 1s ease-in both;
  }
}

@keyframes ani-success-circle {
  to {
    stroke-dashoffset: 782.2565707438586px;
  }
}

@keyframes ani-success-path {
  0% {
    stroke-dashoffset: 62px;
  }
  65% {
    stroke-dashoffset: -5px;
  }
  84% {
    stroke-dashoffset: 4px;
  }
  100% {
    stroke-dashoffset: -2px;
  }
}

.ui-error {
  &-circle {
    stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
    stroke-dashoffset: 260.75219024795285px;
    animation: ani-error-circle 1.2s linear;
  }
  &-line1 {
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line 0.15s 1.2s linear both;
  }
  &-line2 {
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line 0.2s 0.9s linear both;
  }
}

@keyframes ani-error-line {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes ani-error-circle {
  0% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: 0;
  }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
  }
  70% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: -260.75219024795285px;
  }
  100% {
    stroke-dasharray: 260.75219024795285px, 0;
    stroke-dashoffset: -260.75219024795285px;
  }
}

.mat-mdc-dialog-actions {
  background: var(--main-light);
}
