html.croix-blanche {
  --primary: #be111f;
  --primary-hover: #920e19;
  --primary-color: #ffffff;

  --error: #dd4600;
  --error-hover: #af3801;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #eff2f3;
  --border: #dfdfdf;

  --text: #000000;
  --text-light: #000000;
  --text-lightest: #000000;

  --dark-main: #ffffff;

  --dark-text: #000000;
  --dark-text-light: #000000;
}
