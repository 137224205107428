html.bluecime {
  --primary: #0089b9;
  --primary-hover: #73bcd9;
  --primary-color: #ffffff;

  --error: #e91e63;
  --error-hover: #880e4f;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #eaeef3;
  --border: #d1d1d1;

  --text: #0089b9;
  --text-light: #000000;
  --text-lightest: #46bfeb;

  --dark-main: #303030;

  --dark-text: #ffffff;
  --dark-text-light: #c9d3df;
}
