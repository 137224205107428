$spacing: 16px;
$radius: 4px;

$dotborder: 4px;
$dot: 16px;
$line: 4px;

#timeline-content {
  text-align: center;
  //max-width: 400px;
}

/* Timeline */

.timeline {
  border-left: $line solid;
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  letter-spacing: 0.5px;
  position: relative;
  padding: $spacing 0 $spacing $spacing;
  list-style: none;
  text-align: left;

  .event {
    padding-bottom: ($spacing * 0.5);
    margin-bottom: $spacing;
    position: relative;
    padding: $spacing;
    border-radius: 0.5em;
    h5,
    p {
      margin: 0;
    }

    h5 {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      border: none;
    }

    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:after {
      border: $line solid;
      left: ($spacing + $line + ($dot * 0.35)) * -1;
      border-radius: 50%;
      height: $dot;
      width: $dot;
      content: "";
      top: 5px;
    }
  }
}

.timeline {
  border-color: var((--border));
  .event {
    @apply bg-[var(--main-light)] rounded-xl;
    &:after {
      border-color: var(--primary);
      background: var(--main-light);
    }
  }
}
