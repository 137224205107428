html.prieure {
  --primary: #c01125;
  --primary-hover: #9b0e1e;
  --primary-color: #ffffff;

  --error: #dd4600;
  --error-hover: #af3801;
  --error-color: #ffffff;

  --main: #ffffff;
  --main-light: #e2e7eb;
  --border: #d1d5d9;

  --text: #222331;
  --text-light: #708ea0;
  --text-lightest: #d1d5d9;

  --dark-main: #222331;

  --dark-text: #ffffff;
  --dark-text-light: #b6c4ce;
}
